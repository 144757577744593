import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as backEndService from 'newui/Services/Backend/backendService';
import * as constants from 'newui/constants.js'
import style from './style.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { createHomePageGamesDescription, displayInfoNotification, getScreenWidth, isMobile, setTheme, urlToBackgroundNoFoundHandling, urlToBackgroundUrl } from 'newui/Utils/uiUtil'
import GameSearch from '../Search'
import { displayPage } from 'newui/Utils/routerUtil';
import ImagesGroup from 'newui/Generic/Inputs/ImagesGroup';
import HeaderList from './headerList';
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import ApiCache from 'newui/Utils/ApiCache';
import { getImageUrl } from 'newui/Utils/uiUtil';
import UseFreeSpin from './UseFreeSpin';

function Home(props) {

  const navigate = useNavigate();
  const TAB_LOBBY = -1
  const TAB_PROVIDER = -2
  const TAB_FREE_SPIN_GAMES = -3
  const SPECIFIC_SHOW_ALL = -10
  const SEARCH_IN_PROGRESS = -20
  const intl = useIntl()

  const [showTopGames, setShowTopGames] = useState(true);
  const [tabSelected, setTabSelected] = useState(props.specificDisplay ? SPECIFIC_SHOW_ALL : TAB_LOBBY);
  const [lastTabSelected, setLastTabSelected] = useState(props.specificDisplay ? SPECIFIC_SHOW_ALL : TAB_LOBBY);
  const [specificDisplayData, setSpecificDisplayData] = useState(props.specificDisplay);
  const [searchValue, setSearchValue] = useState('');
  const [nbrResult, setNbrResult] = useState(0);

  const [definition, setDefinition] = useState(null);

  const location = useLocation();

  let isDown = false;
  let startX;
  let scrollLeft;
  let moveInProgress = 0;

  const params = useParams(); // USED BY THE REGISTER COMPONENT
  const [searchParams] = useSearchParams();

  useEffect(() => {
    getAllGamesInformation(props.forceTab === TAB_FREE_SPIN_GAMES)
    props.resetHomeInfo();

    if (props.onLoad !== undefined) {
      props.onLoad(params, searchParams);
    }

    if (searchParams && searchParams.getAll('sessionoff') && searchParams.getAll('sessionoff').length > 0) {
      displayInfoNotification(intl.formatMessage({ id: 'newcasino.session.inactive.title', defaultMessage: 'Session timeout' }), intl.formatMessage({ id: 'newcasino.session.inactive.msg', defaultMessage: 'Your session has timed out. Please login.' }))
    }
  },// eslint-disable-next-line
    [])

  useEffect(() => {
    //To directly display the freespin tab, we need to wait the
    //loading of definition
    if (props.forceTab === TAB_FREE_SPIN_GAMES) {
      if (definition) {
        showFreeSpinGames();
      }
    }
  },// eslint-disable-next-line
    [definition])

  function changeTab(newTab) {

    if (newTab !== SEARCH_IN_PROGRESS) {
      setLastTabSelected(newTab);
      if (!(tabSelected === SEARCH_IN_PROGRESS && lastTabSelected === SPECIFIC_SHOW_ALL)) {
        props.resetBorderMenu();
      }

    }

    setTabSelected(newTab);

  }


  function onSuccessGetAllGamesInformation(newDefinition) {
    var baseDefinition = JSON.parse(JSON.stringify(props.definition));
    if (props && props.clearMaintenance) {
      props.clearMaintenance();
    }

    let data = {
      gamescategory: newDefinition[1],
      games: newDefinition[2],
      providers: newDefinition[3],
      gameTypes: newDefinition[4]
    }

    backEndService.setInCache(backEndService.CACHE_CASINO_DATA, newDefinition, ApiCache.getInstance())

    var g = createHomePageGamesDescription(data, baseDefinition);

    setDefinition(g);


    if (props.subSection) {
      for (let i = 0; i < g.games.length; i++) {
        if (g.games[i].code === props.subSection) {
          setTabSelected(i);
          setLastTabSelected(i);
          setShowTopGames(false);
          break;
        }
      }
    }
  }

  function onFailureGetAllGamesInformation(error) {
    console.log(error);
    displayPage(constants.ROUTE_500, navigate);
  }

  function getAllGamesInformation(forceReload) {
    backEndService.getHomePageGamesInformation(ApiPersistence.getInstance().isLogged(), props.definition, onSuccessGetAllGamesInformation, onFailureGetAllGamesInformation, navigate, location.pathname, forceReload)
    backEndService.checkPopup(onSuccessPopup, doNothing, navigate, location.pathname);
  }

  function doNothing(data) {
  }

  function onClosePopup(popup) {
    if (ApiPersistence.getInstance().isLogged()) {
      backEndService.updateClosedPopup(popup.id, doNothing, doNothing, navigate, location.pathname);
    }
    props.closeModal();
  }

  function onSuccessPopup(data) {
    if (data.length > 0 && data[0].length > 0) {
      var thePopupToShow = data[0][0]
      if (props.displayModalMsg) {
        var theTitle = thePopupToShow.titleEn;
        var theBody = thePopupToShow.bodyEn;
        if (ApiPersistence.getInstance().getLanguage() === constants.LANGUAGE_FRENCH) {
          theTitle = thePopupToShow.titleFr;
          theBody = thePopupToShow.bodyFr;
        }
        else if (ApiPersistence.getInstance().getLanguage() === constants.LANGUAGE_PORTUGUESE) {
          theTitle = thePopupToShow.titlePt;
          theBody = thePopupToShow.bodyPt;
        }

        if (thePopupToShow.html === 1) {
          props.displayModalMsg(true, '', { height: thePopupToShow.height, html: theBody }, null, () => onClosePopup(thePopupToShow));
        } else {
          props.displayModalMsg(false, theTitle, theBody, null, () => onClosePopup(thePopupToShow));
        }
      }
    }

    if (data.length > 1 && data[1].length > 0 && data[1][0].giftSend === 'YES') {
      props.forceReloadTopMenu();
      let msg = intl.formatMessage({ id: 'newcasino.birthday.gift' }, { QTE: (data[1][0].spin) })
      displayInfoNotification('', msg);
    }
  }

  function useFreeSpin(data) {
    props.closeModal();
    props.forceReloadTopMenu();
    startGameNow(data);
  }

  function isSearchInProgress() {
    return searchValue.trim().length > 0;
  }

  function startGameNow(gameId) {
    displayPage(constants.ROUTE_PLAY_GAME_BASE + gameId, navigate, location.pathname);
  }

  function startGame(game) {
    if (game.needAccountToPlay && !ApiPersistence.getInstance().isLogged()) {
      displayInfoNotification(intl.formatMessage({ id: 'newcasino.error.need.account', defaultMessage: 'Need an ancount' }), intl.formatMessage({ id: 'newcasino.error.need.account.to.play', defaultMessage: 'You need to login or register to access this game' }))

    } else {
      if (game.freeSpinAllowed && props.getFreeSpinAvailable() > 0) {
        props.displayComponent(
          <UseFreeSpin
            gameId={game.gameId}
            nbrFreeSpins={props.getFreeSpinAvailable()}
            key={Math.random()}
            callback={useFreeSpin} />);

      } else {
        startGameNow(game.gameId);
      }
    }

  }

  function newTabSelected(index) {
    setSearchValue('');
    changeTab(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function newTabSelectedFromTabsList(index) {
    if (isMobile() || ((Date.now() - moveInProgress) < 200)) {
      newTabSelected(index)
    }

  }

  function userSearchFocus(event) {
    if (event.currentTarget.value.trim().length > 0) {
      userSearch(event);
    }
  }


  function userSearch(event) {
    setSearchValue(event.currentTarget.value);

    if (event.currentTarget.value.trim().length === 0) {
      changeTab(lastTabSelected)
    } else {
      changeTab(SEARCH_IN_PROGRESS)
    }


  }

  function showFreeSpinGames() {
    var data = definition.games[0].list;
    setSpecificDisplayData(data)
    changeTab(TAB_FREE_SPIN_GAMES)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function showProviderGames(index) {
    var data = definition.providers.list[index];
    setSpecificDisplayData(data)
    changeTab(SPECIFIC_SHOW_ALL)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function previousButtonClicked(scrollname) {
    document.getElementById(scrollname).scrollBy({
      top: 0,
      left: -450,
      behavior: "smooth",
    });
  }

  function nextButtonClicked(scrollname) {
    document.getElementById(scrollname).scrollBy({
      top: 0,
      left: 450,
      behavior: "smooth",
    });
  }


  function getTopGamesStyleSpacer(index, nbrGame) {
    var toReturn = '';

    if (nbrGame > 1 && (index < (nbrGame - 1))) {
      toReturn = style.GameSelection_topgames_element_spacer;
    }

    return toReturn;
  }
  function getTabStyle(index) {
    var toReturn = style.GameSelection_tab_notSelected;

    if (tabSelected === index) {
      toReturn = style.GameSelection_tab_selected;
    }

    return toReturn;
  }

  function getPriorityLoading(index) {
    if (index < 7) {
      return 'high';
    }

    return 'auto';
  }

  function sliderMouseDown(e) {
    if (isMobile()) { return; }
    var slider = document.getElementById('scrollbarCategories')//e.target;
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;

    moveInProgress = Date.now();
  }

  function sliderMouseLeave(e) {
    if (isMobile()) { return; }
    var slider = document.getElementById('scrollbarCategories')
    isDown = false;
    slider.classList.remove('active');
  }

  function handleNbrResult(nbrResult) {
    setNbrResult(nbrResult)
  }
  function sliderMouseUp(e) {
    if (isMobile()) { return; }
    var slider = document.getElementById('scrollbarCategories')
    isDown = false;
    slider.classList.remove('active');


  }

  function getImageSize() {
    if (getScreenWidth() > 500) {
      return '200px';
    } else {
      return '125px';
    }

  }

  function sliderMouseMove(e) {
    if (isMobile()) { return; }
    pauseEvent(e)
    var slider = document.getElementById('scrollbarCategories')
    if (!isDown) return;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  }

  function pauseEvent(e) {
    if (isMobile()) { return; }
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  try {
    if (definition) {
      return (
        <>
          <div className={`${style.GameSelection_mainDiv}`}>
            <div className={`${style.GameSelection_mainDiv2}`}>
              {showTopGames &&
                <div>
                  <table className={`${style.GameSelection_topgame_table}`}>
                    <tbody>
                      <tr>
                        {definition.topGames.map((item, index) => (
                          <React.Fragment key={'topGamesElement_' + index}>
                            <td className={`${style.GameSelection_topgame_td}`}>
                              <DivClick className={`${style.GameSelection_topgames_element}`} onClick={() => startGame(item)}>
                                <img fetchpriority="high" src={item.image} alt='' className={`${style.GameSelection_topgames_element_img}`}></img>
                              </DivClick>
                            </td>
                            <td className={`${getTopGamesStyleSpacer(index, definition.topGames.length)}`} />
                          </React.Fragment>

                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
              <div className={`${style.GameSelection_tabAndSearch}`}>

                {/********************************************************/}
                <div id={'scrollbarCategories'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_nopaddingbottom}`} onMouseUp={(e) => sliderMouseUp(e)} onMouseMove={(e) => sliderMouseMove(e)} onMouseLeave={(e) => sliderMouseLeave(e)} onMouseDown={(e) => sliderMouseDown(e)}>
                  <ul className={`${style.GameSelection_ul}`}>
                    {(definition.lobby) &&
                      <li key={'lobbyScrollTabLobby'}>
                        <DivClick key={'lobby'} className={`${getTabStyle(TAB_LOBBY)}`} onClick={() => newTabSelectedFromTabsList(TAB_LOBBY)} >
                          <i>
                            <FormattedMessage id={definition.lobby.intl} defaultMessage={definition.lobby.default} />
                          </i>
                        </DivClick>
                      </li>
                    }
                    {(props.getFreeSpinAvailable() > 0) &&
                      <li key={'lobbyScrollTabFreeSpin'}>
                        <DivClick key={'freespins'} className={`${getTabStyle(TAB_FREE_SPIN_GAMES)}`} onClick={() => showFreeSpinGames()} >
                          <i>
                            <FormattedMessage id='newcasino.homegame.freespin' defaultMessage='Free Spin' /> ({props.getFreeSpinAvailable()})
                          </i>
                        </DivClick>
                      </li>
                    }
                    {definition.games.map((item, index) => (
                      <li key={'lobbyScrollTabGames_' + index}>
                        <DivClick key={'gameTab' + index} className={`${getTabStyle(index)}`} onClick={() => newTabSelectedFromTabsList(index)} >
                          <i>
                            <FormattedMessage id={item.intl} defaultMessage={item.default} />
                          </i>
                        </DivClick>
                      </li>
                    ))}

                    {(definition.providers) &&
                      <li key={'lobbyScrollTabProvider'}>
                        <DivClick key={'providers'} className={`${getTabStyle(TAB_PROVIDER)}`} onClick={() => newTabSelectedFromTabsList(TAB_PROVIDER)} >
                          <i>
                            <FormattedMessage id={definition.providers.intl} defaultMessage={definition.providers.default} />
                          </i>
                        </DivClick>
                      </li>
                    }
                  </ul>
                </div>

                {/****************************************/}

                <div className={`${style.GameSelection_tabAndSearch_search}`}>

                  <div className={`${style.borderMenu_row}`}>
                    <div className={`${style.borderMenu_searchBar} ${style.borderMenu_search_grid}`}>
                      <div className={`${style.borderMenu_searchBar_input}`}>
                        <input id='inputID' autoFocus={false} maxLength={50} className={`${style.borderMenu_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.homegame.search', defaultMessage: 'Search Casino' })} onFocus={(e) => userSearchFocus(e)} onChange={(e) => userSearch(e)} />
                      </div>
                      <div className={`${style.borderMenu_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
                    </div>
                  </div>
                </div>
              </div>

              {(isSearchInProgress()) && (
                <div className={`${style.GameSelection_mainDivSearch}`}>
                  <div className={`${style.GameSelection_mainDiv2}`}>
                    <FormattedMessage id='newcasino.search.result.title1' defaultMessage='Search Results - ' /> {nbrResult} <FormattedMessage id='newcasino.search.result.title2' defaultMessage=' item(s)' />
                    <div>
                      <GameSearch keyword={searchValue} displayComponent={props.displayComponent} getFreeSpinAvailable={props.getFreeSpinAvailable} useFreeSpin={useFreeSpin} actionOnResult={handleNbrResult} isCurrentModal={false} />
                    </div>
                  </div>
                </div>
              )}

              {/*
CATEGORY LIST WITH GAME LIST
*/}
              {(tabSelected === TAB_LOBBY && !isSearchInProgress()) && definition.games.map((item, index) => (
                <React.Fragment key={'Lobby_games_' + index}>
                  <HeaderList newTabSelected={() => newTabSelected(index)} previousButtonClicked={() => previousButtonClicked('scrollbar_' + index)} nextButtonClicked={() => nextButtonClicked('scrollbar_' + index)} icon={setTheme(item.images.selected)} title={intl.formatMessage({ id: item.intl, defaultMessage: item.default })} showall={intl.formatMessage({ id: 'newcasino.homegame.showall', defaultMessage: 'Show All' })} />
                  <div id={'scrollbar_' + index} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_paddingbottom}`}>
                    <ul className={`${style.GameSelection_ul}`}>
                      <ImagesGroup images={item.list} imgSize={getImageSize()} onImageClickParam='*' onImageClick={startGame} divStyle={style.GameSelection_showAllGridElement_div} />
                    </ul>
                  </div>
                </React.Fragment>
              ))
              }


              {/*
PROVIDER LIST
*/}
              {(tabSelected === TAB_LOBBY && !isSearchInProgress() && definition.providers.list.length > 0) && (
                <React.Fragment key={'Lobby_providers'}>
                  <HeaderList newTabSelected={() => newTabSelected(TAB_PROVIDER)} previousButtonClicked={() => previousButtonClicked('scrollbar_providers')} nextButtonClicked={() => nextButtonClicked('scrollbar_providers')} icon={setTheme(definition.providers.image)} title={intl.formatMessage({ id: definition.providers.intl, defaultMessage: definition.providers.default })} showall={intl.formatMessage({ id: 'newcasino.homegame.showall', defaultMessage: 'Show All' })} />
                  <div id={'scrollbar_providers'} className={`${style.GameSelection_scrollTestContainer} ${style.GameSelection_scrollTestContainer_paddingbottom}`}>
                    <ul className={`${style.GameSelection_ul}`}>
                      {
                        definition.providers.list.map((itemProvider, indexProvider) => (
                          <li key={'lobbyProvider_' + indexProvider}>
                            <div className={`${style.GameSelection_showAllGridElementForceSize}`}>
                              <div className={`${style.GameSelection_showAllGridElement_div}`}>
                                <div>

                                  <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => showProviderGames(indexProvider)} style={{
                                    backgroundImage: urlToBackgroundNoFoundHandling(setTheme(itemProvider.image), setTheme(getImageUrl('newui/themes/{THEME}/Providers/providerbg.png')))
                                  }}>
                                    <img fetchpriority={getPriorityLoading(indexProvider)} className={`${style.GameSelection_showAllGridElement_img}`} src={setTheme(itemProvider.image)} onError={event => {
                                      event.target.src = setTheme(getImageUrl('newui/themes/{THEME}/Providers/providerbg.png'))
                                      event.onerror = null
                                    }} alt='' />
                                  </DivClick>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>

                </React.Fragment>
              )
              }
              <div className={`${style.GameSelection_showAllGrid}`} >
                {(tabSelected === TAB_PROVIDER && !isSearchInProgress()) && definition.providers.list.map((item, index) => (

                  <div key={'tabProvidersShowAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`}>
                    <div>

                      <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => showProviderGames(index)} style={{
                        backgroundImage: urlToBackgroundNoFoundHandling(setTheme(item.image), setTheme(getImageUrl('newui/themes/{THEME}/Providers/providerbg.png')))
                      }}>

                        <img fetchpriority={getPriorityLoading(index)} className={`${style.GameSelection_showAllGridElement_img}`} src={setTheme(item.image)} onError={event => {
                          event.target.src = setTheme(getImageUrl('newui/themes/{THEME}/Providers/providerbg.png'))
                          event.onerror = null
                        }} alt='' />
                      </DivClick>
                    </div>
                  </div>
                ))}
              </div>

              {(tabSelected === TAB_FREE_SPIN_GAMES && !isSearchInProgress())
                && (
                  <>
                    <div className={`${style.borderMenu_elem_grid}`}>
                      <div className={`${style.borderMenu_elemBar_image}`}>
                        <div className={`${style.image_trans}`} style={{
                          backgroundImage: urlToBackgroundUrl(setTheme('https://playnumbersgame.com/images/general/newui/themes/{THEME}/BorderMenu/IMG_CATEGORY_slots-sel.png'))
                        }} />
                      </div>
                      <div className={`${style.borderMenu_elemBar_title}`}>
                        <FormattedMessage id='newcasino.homegame.freespin' defaultMessage='Free Spin' />
                      </div>
                    </div>
                    <div className={style.freespinInfo}>
                      {intl.formatMessage({ id: 'newcasino.homegame.freespin.sublabel' }, { QTE: (props.getFreeSpinAvailable()) })}
                    </div>
                    < div className={`${style.GameSelection_showAllGrid}`} >
                      {specificDisplayData.map((item, index) => (
                        (item.freeSpinAllowed) && (
                          <div key={'tabSpecificShowAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`}>
                            <div className={`${style.GameSelection_showAllGridElement_center}`}>
                              <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => startGame(item)} style={{
                                backgroundImage: urlToBackgroundUrl(item.image)
                              }}>
                                <img fetchpriority={getPriorityLoading(index)} className={`${style.GameSelection_showAllGridElement_img}`} src={item.image} alt='' />
                              </DivClick>
                            </div>
                          </div>
                        )
                      ))}
                    </div>
                  </>
                )}

              {(tabSelected === SPECIFIC_SHOW_ALL && !isSearchInProgress())
                && (
                  <>
                    <div className={`${style.borderMenu_elem_grid}`}>
                      <div className={`${style.borderMenu_elemBar_image}`}>
                        <div className={`${style.image_trans}`} style={{
                          backgroundImage: setTheme(specificDisplayData.images.selected)
                        }} />
                      </div>
                      <div className={`${style.borderMenu_elemBar_title}`}>
                        <FormattedMessage id={specificDisplayData.intl} defaultMessage={specificDisplayData.default} />
                      </div>
                    </div>
                    < div className={`${style.GameSelection_showAllGrid}`} >
                      {specificDisplayData.action.gameList.map((item, index) => (
                        <div key={'tabSpecificShowAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`}>
                          <div className={`${style.GameSelection_showAllGridElement_center}`}>
                            <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => startGame(item)} style={{
                              backgroundImage: urlToBackgroundUrl(item.image)
                            }}>
                              <img fetchpriority={getPriorityLoading(index)} className={`${style.GameSelection_showAllGridElement_img}`} src={item.image} alt='' />
                            </DivClick>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}


              <div className={`${style.GameSelection_showAllGrid}`} >
                {(tabSelected > -1 && !isSearchInProgress()) && definition.games[tabSelected].list.map((item, index) => (
                  <div key={'tabShowAlls_' + index} className={`${style.GameSelection_showAllGridElement_div}`}>
                    <div className={`${style.GameSelection_showAllGridElement_center}`}>
                      <DivClick className={`${style.GameSelection_showAllGridElement}`} onClick={() => startGame(item)} style={{
                        backgroundImage: urlToBackgroundUrl(item.image)
                      }}>
                        <img fetchpriority={getPriorityLoading(index)} className={`${style.GameSelection_showAllGridElement_img}`} src={item.image} alt='' />
                      </DivClick>
                    </div>
                  </div>
                ))}
              </div>
            </div >
          </div >
        </>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default Home