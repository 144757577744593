import ApiPersistence from './ApiPersistence';

export function getCashierColorPerTheme(color) {



    if (ApiPersistence.getInstance().getTheme() === 'dark') {
        if (color === '422C6C') {
            return '#1A1A1A'
        }

        if (color === 'FBBA20') {
            return '#FBBA20'
        }

        if (color === '614D86') {
            return '#262626'
        }
    }
    return '#' + color;

}

/***************************************************************
From Time to time, Paiement IQ changes its UI. We are overwriting 
their CSS So the following dynamic IDs changes. When it occurs, 
simple get the paiement iq html in Chrome Dev mode and search the
commented field for each value. Replace the id by the new one
****************************************************************/
export function getCashierCSS(isMobile, lang) {
    let toReturn = '';
    const pendingTransactionData = 'data-v-e65d512c'; //class="show-pending-transactions">

    //Present on Main Page of paymentIQ
    const paymentData1 = 'data-v-6636c30d'; // class="payment-method-header
    const paymentData2 = 'data-v-1899b53e'; //.logo-container.list-view-logo-container
    const paymentData3 = 'data-v-dca9ba5c'; //
    const transactionTypeData = 'data-v-cfcf5d5a' //.tabs.horizontal-mode-tab
    const paiementMethodHeaderData = 'data-v-efccc034' //.payment-method-details-header[
    const amountButtonData = 'data-v-4cdae88e' //.set-amount .predefinedvalues button.active[
    //Once on click on crypto
    const cryptoDropBoxData = 'data-v-5ec42852' //.dropdown-toggler[

    //On crypto page with QR Code
    const cryptoData = 'data-v-3b99674a';   //.provider-iframe-container .provider-iframe[
    const pendingTransactionNoticeData = 'data-v-6b1622cd' //.notice-icon[
    const pendingTransactionSummaryRowData = 'data-v-4666080b' //.summary-row[
    const pendingTransactionReceipData = 'data-v-e15371d8'; //.receipt .details-container .success-message-label[
    const separatorMiddleData = 'data-v-99fbf274' //.seperator-middle[
    const providerRedirectData = 'data-v-35cacf9b' //.provider-redirect-iframe-container[

    /**********************************
     * Amount Buttons Modification
     *********************************/
    toReturn = toReturn + `\n\n
    .set-amount .predefinedvalues button[`+ amountButtonData + `]{
      background: `+ getCashierColorPerTheme('422C6C') + ` 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      color: `+ getCashierColorPerTheme('FBBA20') + `;
    }

    .set-amount .predefinedvalues button.active[`+ amountButtonData + `]{
      background: `+ getCashierColorPerTheme('FBBA20') + ` 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      color: `+ getCashierColorPerTheme('422C6C') + `;
    }`

    /**********************************
     * Transaction Types
     *********************************/
    toReturn = toReturn + `\n\n
    .tabs .tab[`+ transactionTypeData + `]:after {
      display: none;
    }

    .tabs.horizontal-mode-tab[`+ transactionTypeData + `] {
      margin-top: 0px;
    }

    #cashier div[`+ transactionTypeData + `] h3 {
        font-family: "Montserrat", sans-serif !important;
        letter-spacing: 0px;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        opacity: 1;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0px;
    }

    .tabs .tab.active[`+ transactionTypeData + `] {
        font-family: "Montserrat", sans-serif !important;
        font-weight: bold !important;
        font-size: 14px;
        color: `+ getCashierColorPerTheme('FBBA20') + ` !important;
        opacity: 1;
    }
  `

    /**********************************
    * Payment Methods
    *********************************/
    toReturn = toReturn + `\n\n
      .flex-row {
        flex-direction: row-reverse;
    }

    .payment-method-details-header[`+ paiementMethodHeaderData + `] {
        display: none;
    }

    .account .logo-container[`+ paymentData2 + `], .logo-container.list-view-logo-container[` + paymentData2 + `] {
        justify-content: flex-start;
    }

    .list-has-radio.app_default .payment-method.active .payment-method-header[`+ paymentData3 + `]:after {
        display: none;
    }

    .payment-method-label {
        font-family: "Montserrat", sans-serif !important;
        font-weight: bold !important;
        font-size: 16px !important;
        color: #FFFFFF !important;
        opacity: 1;
    }

    .payment-method{
        background: `+ getCashierColorPerTheme('422C6C') + ` 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        margin-bottom: 16px;
    }

    .payment-method.active{
        background: `+ getCashierColorPerTheme('614D86') + ` 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 16px #0000001F;
        border: 2px solid `+ getCashierColorPerTheme('FBBA20') + `;
        border-radius: 12px;
        opacity: 1;
    }

    .layout-horizontal .payment-method-header[`+ paymentData1 + `]:before, .list-has-radio .payment-method-header[` + paymentData1 + `]:before {
        content: "";
        min-width: 24px;
        width: 24px;
        height: 24px;
        position: relative;
        border: 0px;
        background: transparent url('https://playnumbersgame.com/images/general/paymentiq/IMG_Circle.png') 0% 0% no-repeat padding-box;
    }

    .list-has-radio.app_default .payment-method.active .payment-method-header[`+ paymentData1 + `]:before {
        background: transparent url('https://playnumbersgame.com/images/general/paymentiq/IMG_Circle-Check.svg') 0% 0% no-repeat padding-box;
    }
    `

    /**********************************
    * Crypto Currency -> Background for dropdown Fix
    *********************************/
    toReturn = toReturn + `\n\n
    .dropdown-toggler[`+ cryptoDropBoxData + `] {
        background-color: white;
    }

    .dropdown-container[`+ cryptoDropBoxData + `] {
        background-color: `+ getCashierColorPerTheme('614D86') + `;
    }
    `

    /**********************************
    * Interact Copyright message (French or English)
    *********************************/
    if (lang === 'fr-FR') {
        toReturn = toReturn + `\n\n
            #interactCustom {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_pt {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_fr {
                display: block;
                color: #FFFFFF !important;
            }
    `
    } else if (lang === 'pt-BR') {
        toReturn = toReturn + `\n\n
        #interactCustom {
            display: none;
            color: #FFFFFF !important;
            font-size: 12px;
        }

        #interactCustom_pt {
            display: block;
            color: #FFFFFF !important;
        }

        #interactCustom_fr {
            display: none;
            color: #FFFFFF !important;
            font-size: 12px;
        }
        `
    } else {
        toReturn = toReturn + `\n\n
            #interactCustom {
                display: block;
                color: #FFFFFF !important;
                font-size: 12px;
            }

            #interactCustom_pt {
                display: none;
                color: #FFFFFF !important;
            }

            #interactCustom_fr {
                display: none;
                color: #FFFFFF !important;
                font-size: 12px;
            }
    `}

    /**********************************
    * EcoPayz ugly logo Rounded
    *********************************/
    toReturn = toReturn + `\n\n
            .ecopayz-custom-template .img-container{
                background: white 0% 0% no-repeat padding-box;
                box-shadow: 0px 8px 16px #0000001F;
                border: 2px solid white;
                border-radius: 12px;
                width: 100px;
                padding-top: 8px;
                padding-bottom: 1px;
                padding-left: 8px;
                opacity: 1;
        }

        .ecopayz-custom-template div p{
            color: #FFFFFF;
    }
        `

    /**********************************
   * Muchbetter ugly logo Rounded
   *********************************/
    toReturn = toReturn + `\n\n
         .muchbetter-custom-template .img-container{
             background: white 0% 0% no-repeat padding-box;
             box-shadow: 0px 8px 16px #0000001F;
             border: 2px solid white;
             border-radius: 12px;
             width: 105px;
             padding-top: 8px;
             padding-bottom: 1px;
             padding-left: 8px;
             opacity: 1;
     }

     .muchbetter-custom-template div p{
        color: #FFFFFF;
}
     `

    /**********************************
    * Pending Transactions
    const pendingTransactionData = 'data-v-186064a9';        //YES    
    const pendingTransactionNoticeData = 'data-v-6b1622cd' //NO
    const pendingTransactionSummaryRowData = 'data-v-4666080b' //NO
    const pendingTransactionReceipData = 'data-v-be7199b6' //NO
    const separatorMiddleData = 'data-v-99fbf274' //NO
    const providerRedirectData = 'data-v-35cacf9b' //NO
    *********************************/
    toReturn = toReturn + `\n\n
     .cashier-section-header{
         color: #FBBA20 !important;
     }
 
     .show-pending-transactions .header-container span[`+ pendingTransactionData + `] {
         color: #FFFFFF !important;
     }
 
     .show-pending-transactions[`+ pendingTransactionData + `] span{
         color: #FFFFFF;
     }
 
     .cancel-all-pending{
         color:red !important;
     }
 
     .cancel-pending-transaction{
         color:red !important;
     }
 
     .show-pending-transactions .transaction-details-container[`+ pendingTransactionData + `] {
         border: 2px solid #FBBA20;
         border-radius: 12px;
     }
 
     .notice-icon[`+ pendingTransactionNoticeData + `], .notice-text[` + pendingTransactionNoticeData + `] {
         color: #FFFFFF !important;
     }
 
     #cashier .h1, #cashier .h2, #cashier .h3, #cashier .h4, #cashier h1:not(.ecommerce-amount), #cashier h2, #cashier h3:not(.notice-text), #cashier h4 {
         color: #FBBA20;
     }
 
     .receipt p[`+ pendingTransactionReceipData + `] {
         color: #FFFFFF;
     }
 
     .receipt .details-container .success-message-label[`+ pendingTransactionReceipData + `], .receipt .details-container .success-message-value[` + pendingTransactionReceipData + `] {
         color: #FFFFFF !important;
     }
 
     .summary-row[`+ pendingTransactionSummaryRowData + `] {
         color: #FFFFFF !important;
     }
 
     #cashier .label, #cashier h5, #cashier label:not(.input-label-float) {
         color: #FFFFFF !important;
     }
 
     #cashier .label, #cashier h5, #cashier label:not(.input-label-float) {
         color: #FFFFFF !important;
     }
 
     #cashier .make-new-payment-text {
         color: #FFFFFF !important;
     }
 
     .seperator-middle[`+ separatorMiddleData + `] {
         color: #FFFFFF !important;
     }
 
     .seperator-line[`+ separatorMiddleData + `] {
         background: #FBBA20;
     }
 
     .provider-redirect-iframe-container[`+ providerRedirectData + `] {
         margin-top: 20px;
         border-radius: 12px;
     }
 
     .show-pending-transactions .header-container span.cancel-all-pending[`+ pendingTransactionData + `] {
         display: none;
     }
 `


    /**********************************
       * Crypto Screen
       *********************************/
    toReturn = toReturn + `\n\n
    .oldCrypto{
        display: none;
    }

    .newCrypto{
        display: block;
    }
`
    toReturn = toReturn + `\n\n

    .provider-iframe-container .provider-iframe[`+ cryptoData + `] {
        background-color: transparent !important;

    }
`
    return toReturn;
}